import React from "react";
import { useSpring, animated } from "@react-spring/web";
import { useInView } from "react-intersection-observer";

const Pulumi = () => {

    // Set up Intersection Observer
  const [ref, inView] = useInView({ threshold: 0.3 });

  // Set up react-spring for opacity
  const [styles, api] = useSpring(() => ({
    opacity: 0,  // Start fully transparent
    config: { tension: 120, friction: 14 },
  }));

  // Trigger the fade-in once the Pulumi component is in view
  React.useEffect(() => {
    if (inView) {
      api.start({ opacity: 1 });  // Animate from 0 to 1
    }
  }, [inView, api]);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
    <div ref={ref}>
      <svg
        id="Pulumi"
        xmlns="http://www.w3.org/2000/svg"
        width="300"
        height="150"
        viewBox="0 0 300 150"
      >
        <animated.g style={styles}>
        <path
          d="M23.55,78.88c2.53-1.46,2.53-6.19,0-10.57-2.53-4.37-6.62-6.74-9.16-5.27-2.53,1.46-2.53,6.19,0,10.57,2.53,4.37,6.62,6.74,9.16,5.27ZM23.56,88.24c2.53,4.37,2.52,9.1,0,10.57-2.53,1.46-6.63-.9-9.16-5.27-2.53-4.37-2.52-9.1,0-10.57,2.53-1.46,6.63.9,9.16,5.27ZM40.81,98.22c2.53,4.37,2.52,9.1,0,10.57-2.53,1.46-6.63-.9-9.16-5.28-2.53-4.37-2.52-9.1,0-10.57,2.53-1.46,6.63.9,9.16,5.27ZM40.8,78.28c2.53,4.37,2.52,9.1,0,10.57-2.53,1.46-6.63-.9-9.16-5.27-2.53-4.37-2.52-9.1,0-10.57,2.53-1.46,6.63.9,9.16,5.27Z"
          fill="#f2707e"
          fillRule="evenodd"
        />
        <path
          d="M75.3,73.61c2.53-4.37,2.52-9.1,0-10.57-2.53-1.46-6.63.9-9.16,5.27-2.53,4.37-2.52,9.1,0,10.57,2.53,1.46,6.63-.9,9.16-5.27ZM75.3,82.98c2.53,1.46,2.54,6.19,0,10.57-2.53,4.37-6.62,6.74-9.16,5.27s-2.53-6.19,0-10.57c2.53-4.37,6.62-6.74,9.16-5.27ZM58.06,92.93c2.53,1.46,2.54,6.19,0,10.57-2.53,4.37-6.62,6.74-9.16,5.27-2.53-1.46-2.53-6.19,0-10.57,2.53-4.37,6.62-6.74,9.16-5.27ZM58.05,73c2.53,1.46,2.53,6.19,0,10.57-2.53,4.37-6.62,6.74-9.16,5.27-2.53-1.46-2.53-6.19,0-10.57,2.53-4.37,6.62-6.74,9.16-5.27Z"
          fill="#8b3591"
          fillRule="evenodd"
        />
        <path
          d="M53.99,46.08c0,2.92-4.09,5.29-9.15,5.29s-9.15-2.37-9.15-5.29,4.09-5.29,9.15-5.29,9.15,2.37,9.15,5.29ZM36.75,56.02c0,2.92-4.09,5.29-9.15,5.29s-9.15-2.37-9.15-5.29,4.09-5.29,9.15-5.29,9.15,2.37,9.15,5.29ZM62.11,61.31c5.05,0,9.15-2.37,9.15-5.29s-4.09-5.29-9.15-5.29-9.15,2.37-9.15,5.29,4.09,5.29,9.15,5.29ZM53.99,65.99c0,2.92-4.09,5.29-9.15,5.29s-9.15-2.37-9.15-5.29,4.09-5.29,9.15-5.29,9.15,2.37,9.15,5.29Z"
          fill="#f6bf28"
          fillRule="evenodd"
        />
        <path
          d="M176.99,53.08h3.4c1.06,0,1.64.59,1.64,1.64v39.48c0,1.06-.59,1.64-1.64,1.64h-3.4c-1.06,0-1.64-.59-1.64-1.64v-39.48c0-1.06.59-1.64,1.64-1.64ZM111.6,95.85c1.06,0,1.64-.59,1.64-1.64v-13.22h6.89c8.74,0,14.22-5.22,14.22-13.07s-5.48-13.07-14.22-13.07h-12.16c-1.06,0-1.64.59-1.64,1.64v37.72c0,1.06.59,1.64,1.64,1.64h3.63ZM113.24,60.67h6.45c4.92,0,7.71,2.84,7.71,7.24s-2.78,7.27-7.71,7.27h-6.45v-14.51ZM139.27,67.15v17.06c0,7.47,4.84,12.31,12.14,12.31,3.87,0,7.18-1.91,9.23-4.69v2.37c0,1.06.59,1.64,1.64,1.64h3.43c1.06,0,1.64-.59,1.64-1.64v-27.05c0-1.06-.59-1.64-1.64-1.64h-3.43c-1.06,0-1.64.59-1.64,1.64v14.89c0,5.04-3.63,8.59-7.86,8.59s-6.83-2.87-6.83-6.98v-16.5c0-1.06-.59-1.64-1.64-1.64h-3.4c-1.06,0-1.64.59-1.64,1.64ZM189.48,67.15v17.06c0,7.47,4.84,12.31,12.13,12.31,3.87,0,7.18-1.91,9.23-4.69v2.37c0,1.06.59,1.64,1.64,1.64h3.43c1.06,0,1.64-.59,1.64-1.64v-27.05c0-1.06-.59-1.64-1.64-1.64h-3.43c-1.06,0-1.64.59-1.64,1.64v14.89c0,5.04-3.63,8.59-7.86,8.59s-6.83-2.87-6.83-6.98v-16.5c0-1.06-.59-1.64-1.64-1.64h-3.4c-1.06,0-1.64.59-1.64,1.64ZM272.55,77.14v17.09c0,1.06-.59,1.64-1.64,1.64h-3.43c-1.06,0-1.64-.59-1.64-1.64v-16.5c0-4.1-2.55-6.98-6.3-6.98s-7.36,3.81-7.36,8.41v15.07c0,1.06-.59,1.64-1.64,1.64h-3.43c-1.06,0-1.64-.59-1.64-1.64v-16.5c0-4.1-2.55-6.98-6.3-6.98s-7.36,3.46-7.36,8.41v15.07c0,1.06-.59,1.64-1.64,1.64h-3.43c-1.05,0-1.64-.59-1.64-1.64v-27.08c0-1.06.59-1.64,1.64-1.64h3.43c1.06,0,1.64.59,1.64,1.64v2.32c2.14-2.81,5.22-4.66,8.76-4.66,4.57,0,8.41,2.37,10.32,6.3,1.99-3.81,5.69-6.3,10.02-6.3,6.6,0,11.64,4.87,11.64,12.34ZM287.5,57.24c0-2.32-1.82-4.1-4.16-4.1s-4.13,1.79-4.13,4.1,1.79,4.07,4.13,4.07,4.16-1.79,4.16-4.07ZM286.68,67.15c0-1.06-.59-1.64-1.64-1.64h-3.4c-1.06,0-1.64.59-1.64,1.64v27.05c0,1.06.59,1.64,1.64,1.64h3.4c1.06,0,1.64-.59,1.64-1.64v-27.05Z"
          fill="var(--text-color)"
          fillRule="evenodd"
        />
        </animated.g>
      </svg>
    </div>
    </div>
  );
};

export default Pulumi;
